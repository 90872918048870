type ENV_VARS =
    | 'FIREBASE_API_KEY'
    | 'ALGOLIA_INDEX_NAME'
    | 'ALGOLIA_INDEX_SUGGESTIONS'
    | 'AMPLITUDE_API_KEY'
    | 'AXEPTIO_CLIENT_ID'
    | 'FIREBASE_APP_ID'
    | 'FIREBASE_AUTH_DOMAIN'
    | 'FIREBASE_DATABASE_URL'
    | 'FIREBASE_MEASUREMENT_ID'
    | 'FIREBASE_PROJECT_ID'
    | 'FIREBASE_STORAGE_BUCKET'
    | 'SENTRY_DSN';

export const isProductionEnv = (): boolean =>
    process.env.NEXT_PUBLIC_VERCEL_GITHUB_COMMIT_REF === 'master';

/**
 * When updating env variables, make sure to update the type ENV_VARS
 * and env.d.ts file.
 * @param key name key of the env var
 * @returns value of the env var
 */
export const getEnvVar = (key: ENV_VARS): string => {
    const isProd = isProductionEnv();

    switch (key) {
        case 'ALGOLIA_INDEX_NAME':
            return isProd
                ? process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME_PROD
                : process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME_PREPROD;
        case 'ALGOLIA_INDEX_SUGGESTIONS':
            return process.env.NEXT_PUBLIC_ALGOLIA_INDEX_SUGGESTIONS;
        case 'FIREBASE_API_KEY':
            return isProd
                ? process.env.NEXT_PUBLIC_FIREBASE_API_KEY_PROD
                : process.env.NEXT_PUBLIC_FIREBASE_API_KEY_PREPROD;
        case 'FIREBASE_AUTH_DOMAIN':
            return isProd
                ? process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN_PROD
                : process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN_PREPROD;
        case 'FIREBASE_DATABASE_URL':
            return isProd
                ? process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL_PROD
                : process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL_PREPROD;

        case 'FIREBASE_STORAGE_BUCKET':
            return isProd
                ? process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET_PROD
                : process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET_PREPROD;
        case 'FIREBASE_PROJECT_ID':
            return isProd
                ? process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID_PROD
                : process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID_PREPROD;
        case 'FIREBASE_APP_ID':
            return isProd
                ? process.env.NEXT_PUBLIC_FIREBASE_APP_ID_PROD
                : process.env.NEXT_PUBLIC_FIREBASE_APP_ID_PREPROD;
        case 'FIREBASE_MEASUREMENT_ID':
            return isProd
                ? process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID_PROD
                : process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID_PREPROD;
        case 'SENTRY_DSN':
            return process.env.NEXT_PUBLIC_SENTRY_DSN;
        case 'AMPLITUDE_API_KEY':
            return isProd
                ? process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY_PROD
                : process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY_PREPROD;
        case 'AXEPTIO_CLIENT_ID':
            return process.env.NEXT_PUBLIC_AXEPTIO_CLIENT_ID;
        default:
            throw new Error(`key ${key} is not recognized `);
    }
};
